exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body .custom_blocklyText_1J-Nw {\n    font-size: 14pt!important;\n}\n\n.custom_blocklyControlWait_2vr7B {\n    width: 150px !important;\n    height: 50px !important;\n}\n\n.custom_blocklyText_1J-Nw {\n    fill: #fff;\n    font-family: \"Helvetica Neue\", Helvetica, sans-serif;\n    font-size: 14pt!important;\n    font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"blocklyText": "custom_blocklyText_1J-Nw",
	"blocklyControlWait": "custom_blocklyControlWait_2vr7B"
};